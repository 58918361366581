.App {
  text-align: center;
  background-image: url('./img/sunset.jpeg');
  background-size: cover;
  font-family: Garamond;
  overflow-x: hidden;
}

.login-page {
  background-image: url('./img/sunset.jpeg');
}

.welcome-container {
  color: white;
  font-weight: bold;
}

.schedule-container table{
  color: white;
  text-align: left;
}

.schedule-container a {
  color: white;
}

#ref-link {
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}